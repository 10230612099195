/* Copyright 2019-2023 Martin Trenkmann
 * https://ngrams.dev
 */
import $ from "jquery";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "../public/libs/fomantic/semantic"; // Has no typings and should not be bundled
import "../public/libs/switchify/switchify"; // Has no typings and should not be bundled
// -------------------------------------------------------------------------------------------------
// Types
// -------------------------------------------------------------------------------------------------
class Corpus {
    constructor() {
        this.name = "";
        this.language = "";
        this.shortName = "";
        this.numNgrams = 0;
    }
}
Corpus.emptyInstance = new Corpus();
class NgramLite {
    constructor() {
        this.id = "";
        this.abstract = false;
        this.absTotalMatchCount = 0;
        this.relTotalMatchCount = 0;
        this.tokens = [];
    }
    static fromJson(json) {
        // https://stackoverflow.com/questions/42899570/method-in-typescript-class-give-error-is-not-a-function
        return Object.assign(new NgramLite(), json);
    }
    toString() {
        return this.tokens.map((token) => token.text).join(" ");
    }
}
class Ngram extends NgramLite {
    constructor() {
        super(...arguments);
        this.stats = [];
    }
}
Ngram.emptyInstance = new Ngram();
class NgramStatsHistogram {
    constructor(stats, height) {
        var _a;
        this.container = document.createElement("div");
        this.container.style.height = height;
        this.stats = stats;
        this.root = am5.Root.new(this.container, {
            // https://www.amcharts.com/docs/v5/getting-started/root-element/#Safe_resolution
            useSafeResolution: false,
        });
        this.absoluteNumberFormat = this.root.numberFormatter.get("numberFormat");
        // https://www.amcharts.com/docs/v5/concepts/formatters/
        // Set precision depending on actual data.
        // Google shows 10 decimal digits by default.
        const minRelMatchCount = this.stats.reduce((previous, stat) => {
            return previous.relMatchCount < stat.relMatchCount ? previous : stat;
        }).relMatchCount;
        if (minRelMatchCount > 0) {
            let exponent = Math.abs(parseInt(minRelMatchCount.toExponential().split("e")[1]));
            exponent -= 2; // The "%" in numberFormatter converts number to percentage.
            this.relativeNumberFormat = `###.${"0".repeat(exponent)}%`;
        }
        // https://www.amcharts.com/docs/v5/concepts/themes/
        // Turned off b/c animation of xAxis labels when switching Absolute / Relative numbers is odd.
        // this.root.setThemes([am5themes_Animated.new(this.root)]);
        const chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
            panX: true,
            panY: false,
            wheelY: "zoomX",
        }));
        // https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/#Zooming_with_scrollbars
        this.scrollbar = am5.Scrollbar.new(this.root, { orientation: "horizontal", maxHeight: 7 });
        const scrollbarSettings = { height: 16, width: 16, icon: undefined };
        this.scrollbar.startGrip.setAll(scrollbarSettings);
        this.scrollbar.endGrip.setAll(scrollbarSettings);
        chart.set("scrollbarX", this.scrollbar);
        chart.bottomAxesContainer.children.push(this.scrollbar); // Move scrollbar to bottom
        // https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/#Zoom_out_button
        // https://www.amcharts.com/docs/v5/concepts/common-elements/buttons/
        chart.zoomOutButton.set("forceHidden", true);
        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(this.root, {
            baseInterval: { timeUnit: "year", count: 1 },
            maxDeviation: 0,
            renderer: am5xy.AxisRendererX.new(this.root, {}),
        })
        // DateAxis renders gaps explicitly in contrast to GaplessDateAxis.
        // Using ValueAxis would make the chart NOT auto zoom on panning.
        );
        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
            renderer: am5xy.AxisRendererY.new(this.root, {}),
        }));
        // const fontFamily = "Inter";
        // xAxis.get("renderer").labels.template.set("fontFamily", fontFamily);  // No font features
        // xAxis.get("renderer").labels.template.adapters.add("html", function (html, target) {
        //   return `<span style="font-family:${fontFamily}">{value.formatDate('yyyy')}</span>`;
        // });
        // yAxis.get("renderer").labels.template.set("fontFamily", fontFamily); // No font features
        // yAxis.get("renderer").labels.template.adapters.add("html", function (html, target) {
        //   return `<span style="font-family:${fontFamily}">{value}</span>`;
        // });
        const cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
        cursor.lineY.set("visible", false);
        const tooltip = am5.Tooltip.new(this.root, {
            animationDuration: 100,
            getFillFromSprite: false,
            labelText: "[bold]{valueX.formatDate('yyyy')}[/]\n{valueY}",
            pointerOrientation: "horizontal",
        });
        // https://www.amcharts.com/docs/v5/concepts/common-elements/tooltips/#Colors
        // Settings this in the constructor above does not work...
        (_a = tooltip.get("background")) === null || _a === void 0 ? void 0 : _a.setAll({
            fill: am5.color("#f0f0f2"),
            fillOpacity: 1,
            strokeOpacity: 0,
        });
        this.series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "year",
            tooltip: tooltip,
        }));
        // https://www.amcharts.com/docs/v5/charts/xy-chart/zoom-and-pan/#Specific_range
        const series = this.series;
        const scrollbar = this.scrollbar;
        this.series.events.on("datavalidated", function (event) {
            const maxYearRange = 200;
            const yearRange = stats[stats.length - 1].year - stats[0].year;
            if (yearRange > maxYearRange) {
                // Zoom in to show last 200 years. Google shows 1800-2019 by default.
                scrollbar.set("start", 1 - maxYearRange / yearRange);
            }
            // Fire only once on initial load.
            series.events.off("datavalidated");
        });
        this.showRelativeNumbers();
    }
    showNumbers(absoluteOrRelative) {
        this.root.numberFormatter.set("numberFormat", absoluteOrRelative ? this.absoluteNumberFormat : this.relativeNumberFormat);
        const date = new Date(0, 0);
        this.series.data.setAll(this.stats.map((stat) => {
            date.setFullYear(stat.year);
            return {
                year: date.getTime(),
                value: absoluteOrRelative ? stat.absMatchCount : stat.relMatchCount,
            };
        }));
    }
    showAbsoluteNumbers() {
        this.showNumbers(true);
    }
    showRelativeNumbers() {
        this.showNumbers(false);
    }
}
NgramStatsHistogram.emptyInstance = new NgramStatsHistogram([{ year: 0, absMatchCount: 0, relMatchCount: 0 }], "100px");
// TODO Make component
class ResultList {
    constructor(selector) {
        this.ngrams = [];
        if (selector) {
            this.element = $(selector);
        }
    }
    append(ngrams) {
        var _a;
        if (ngrams.length > 0) {
            this.ngrams.push(...ngrams);
            const maxAbsTotalMatchCount = this.ngrams[0].absTotalMatchCount;
            (_a = this.element) === null || _a === void 0 ? void 0 : _a.append(ngrams.map((ngram) => ResultListItem.render(ngram, maxAbsTotalMatchCount)));
        }
    }
    clear() {
        var _a;
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.empty();
        this.ngrams.length = 0;
    }
    hide() {
        var _a;
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.hide();
    }
    set(ngrams) {
        this.clear();
        this.append(ngrams);
    }
    show() {
        var _a;
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.show();
    }
    size() {
        return this.ngrams.length;
    }
}
class ResultListItem {
    static render(ngram, maxAbsTotalMatchCount) {
        const item = $(`
      <div class="item">
        <div class="text"></div>
        <div class="tmc">${formatNumber(ngram.absTotalMatchCount)}</div>
        <div class="ui tiny progress">
          <div class="bar"></div>
        </div>
        <div class="icons">
          <span class="icon info" title="Toggle basic ngram info">
            <i class="fa-solid fa-circle-info fa-fw"></i>
          </span>
          <span class="icon stats" title="Toggle match count by year">
            <i class="fa-solid fa-chart-simple fa-fw"></i>
          </span>
          <span class="spinner">
            <i class="fa-solid fa-asterisk fa-spin fa-fw"
              style="--fa-animation-duration: 750ms; --fa-animation-timing: ease-in-out">
            </i>
          </span>
          <span class="icon snippets" title="Toggle text snippets">
            <i class="fa-solid fa-file-lines fa-fw"></i>
          </span>
        </div>
        <div class="info">
          <div class="property">
            <div class="name">Corpus</div>
            <div class="value">${session.corpus.name}</div>
          </div>
          <div class="property">
            <div class="name">Length</div>
            <div class="value">${ngram.tokens.length}</div>
          </div>
          <div class="property">
            <div class="name">Absolute total match count</div>
            <div class="value">${ngram.absTotalMatchCount}</div>
          </div>
          <div class="property">
            <div class="name">Relative total match count</div>
            <div class="value">${ngram.relTotalMatchCount}</div>
          </div>
          <div class="property">
            <div class="name">ID</div>
            <div class="value">${ngram.id}</div>
          </div>
        </div>
        <div class="stats">
          <div class="header">
            <a class="ui basic label absolute">Absolute</a>
            <a class="ui basic label relative active">Relative</a>
            <div class="icon fullscreen">
              <i class="fa-solid fa-expand" title="Full screen"></i>
            </div>
          </div>
          <div class="chart"></div>
        </div>
        <div class="snippets"></div>
      </div>
    `);
        const text = item.find(".text");
        ngram.tokens.forEach((token) => {
            const span = $("<span>", { class: "type" + token.type });
            if (token.completed)
                span.addClass("completed");
            if (token.inserted)
                span.addClass("inserted");
            span.text(token.text);
            text.append(" ", span);
        });
        item.find(".progress").progress({
            percent: (100 * ngram.absTotalMatchCount) / maxAbsTotalMatchCount,
            showActivity: false,
        });
        // ---------------------------------------------------------------------------------------------
        // Info icon click handler
        // ---------------------------------------------------------------------------------------------
        item.find(".info.icon").on("click", () => {
            item.find("div.info").toggle();
        });
        // ---------------------------------------------------------------------------------------------
        // Stats icon click handler
        // ---------------------------------------------------------------------------------------------
        const spinner = item.find(".spinner");
        const statsIcon = item.find(".stats.icon");
        let ngramFull = Ngram.emptyInstance;
        let histogram = NgramStatsHistogram.emptyInstance;
        if (ngram.abstract) {
            statsIcon.addClass("disabled");
            statsIcon.attr("title", "Collapsed ngrams have no year-based match counts");
        }
        else {
            statsIcon.on("click", async () => {
                const statsContainer = item.find("div.stats");
                if (statsContainer.is(":visible")) {
                    statsContainer.hide();
                    return;
                }
                const errorClass = "error";
                const chartContainer = statsContainer.find(".chart");
                if (chartContainer.children().length == 0 || statsContainer.hasClass(errorClass)) {
                    statsIcon.hide();
                    spinner.show();
                    const url = makeNgramRequestUrl(session.corpus.shortName, ngram.id);
                    const response = await fetch(url);
                    if (response.ok) {
                        statsContainer.removeClass(errorClass);
                        ngramFull = await response.json();
                        histogram = new NgramStatsHistogram(ngramFull.stats, "300px");
                        chartContainer.append(histogram.container);
                    }
                    else {
                        statsContainer.addClass(errorClass);
                    }
                    spinner.hide();
                    statsIcon.show();
                }
                statsContainer.show();
            });
        }
        // ---------------------------------------------------------------------------------------------
        // Text snippets icon click handler
        // ---------------------------------------------------------------------------------------------
        const snippetsIcon = item.find(".snippets.icon").on("click", async () => {
            const snippetsContainer = item.find("div.snippets");
            if (snippetsContainer.is(":visible")) {
                snippetsContainer.hide();
                return;
            }
            const errorClass = "error";
            if (snippetsContainer.children().length == 0 ||
                snippetsContainer.find(errorClass).length > 0) {
                snippetsContainer.empty();
                snippetsIcon.hide();
                spinner.show();
                const ngramText = ngram.toString();
                const url = makeSnippetsRequestUrl(ngramText, session.corpus.language);
                const response = await fetch(url);
                if (response.ok) {
                    const json = await response.json();
                    const snippets = json.items
                        .filter((item) => {
                        const snippet = item.searchInfo.textSnippet;
                        return snippet.includes(`<b>${ngramText}</b>`);
                    })
                        .map((item) => item.searchInfo.textSnippet);
                    if (snippets.length > 0) {
                        snippetsContainer.append(makeSnippetsList(snippets));
                    }
                    else {
                        snippetsContainer.append(makeSnippetWarningEmptyResult());
                    }
                }
                else {
                    snippetsContainer.append(makeSnippetWarningUnreachable());
                }
                spinner.hide();
                snippetsIcon.show();
            }
            snippetsContainer.show();
        });
        // ---------------------------------------------------------------------------------------------
        // Absolute / Relative button click handler
        // ---------------------------------------------------------------------------------------------
        const activeClass = "active";
        const absButton = item.find(".absolute").on("click", () => {
            if (absButton.hasClass(activeClass))
                return;
            relButton.removeClass(activeClass);
            absButton.addClass(activeClass);
            histogram === null || histogram === void 0 ? void 0 : histogram.showAbsoluteNumbers();
        });
        const relButton = item.find(".relative").on("click", () => {
            if (relButton.hasClass(activeClass))
                return;
            absButton.removeClass(activeClass);
            relButton.addClass(activeClass);
            histogram === null || histogram === void 0 ? void 0 : histogram.showRelativeNumbers();
        });
        // ---------------------------------------------------------------------------------------------
        // Fullscreen icon click handler
        // ---------------------------------------------------------------------------------------------
        item.find(".fullscreen.icon").on("click", () => {
            const modal = getNgramStatsModal();
            modal.data("ngramFull", ngramFull);
            modal.data("ngramLite", ngram);
            modal.modal("show");
        });
        return item;
    }
}
// TODO Make component
class ResultSizeLabel {
    constructor(selector) {
        if (selector) {
            this.element = $(selector);
            this.setValue(0);
        }
    }
    setValue(value) {
        var _a;
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.text(value + (value == 1 ? " Result" : " Results"));
    }
    show() {
        var _a;
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.show();
    }
    hide() {
        var _a;
        (_a = this.element) === null || _a === void 0 ? void 0 : _a.hide();
    }
}
class SearchFlags {
    constructor() {
        this.caseSensitive = false;
        this.collapseResult = false;
        this.dontInterpretOperators = false;
        this.dontNormalizeQueryTerms = false;
        this.dontTokenizeQueryTerms = false;
        this.excludePunctuationMarks = false;
        this.excludeSentenceBoundaries = false;
    }
    static fromString(s) {
        const flags = new SearchFlags();
        flags.caseSensitive = s.includes("cs");
        flags.collapseResult = s.includes("cr");
        flags.excludePunctuationMarks = s.includes("ep");
        flags.excludeSentenceBoundaries = s.includes("es");
        if (s.includes("ra")) {
            flags.dontInterpretOperators = true;
            flags.dontNormalizeQueryTerms = true;
            flags.dontTokenizeQueryTerms = true;
        }
        else {
            flags.dontInterpretOperators = s.includes("ri");
            flags.dontNormalizeQueryTerms = s.includes("rn");
            flags.dontTokenizeQueryTerms = s.includes("rt");
        }
        return flags;
    }
    equals(other) {
        return (this.caseSensitive == other.caseSensitive &&
            this.collapseResult == other.collapseResult &&
            this.dontInterpretOperators == other.dontInterpretOperators &&
            this.dontNormalizeQueryTerms == other.dontNormalizeQueryTerms &&
            this.dontTokenizeQueryTerms == other.dontTokenizeQueryTerms &&
            this.excludePunctuationMarks == other.excludePunctuationMarks &&
            this.excludeSentenceBoundaries == other.excludeSentenceBoundaries);
    }
    toBoolean() {
        return (this.caseSensitive ||
            this.collapseResult ||
            this.excludePunctuationMarks ||
            this.excludeSentenceBoundaries ||
            this.dontInterpretOperators ||
            this.dontNormalizeQueryTerms ||
            this.dontTokenizeQueryTerms);
    }
    toString() {
        let s = "";
        if (this.caseSensitive) {
            s += "cs";
        }
        if (this.collapseResult) {
            s += "cr";
        }
        if (this.excludePunctuationMarks) {
            s += "ep";
        }
        if (this.excludeSentenceBoundaries) {
            s += "es";
        }
        if (this.dontInterpretOperators &&
            this.dontNormalizeQueryTerms &&
            this.dontTokenizeQueryTerms) {
            s += "ra";
        }
        else {
            if (this.dontInterpretOperators) {
                s += "ri";
            }
            if (this.dontNormalizeQueryTerms) {
                s += "rn";
            }
            if (this.dontTokenizeQueryTerms) {
                s += "rt";
            }
        }
        return s;
    }
}
class Session {
    constructor() {
        this.query = "";
        this.flags = new SearchFlags();
        this.corpus = Corpus.emptyInstance;
        // True if session state is newer than UI state.
        this.dirty = false;
    }
}
// -------------------------------------------------------------------------------------------------
// Global variables and constants
// -------------------------------------------------------------------------------------------------
const corpora = [
    {
        name: "English",
        language: "en",
        shortName: "eng",
        numNgrams: 23568635820,
    },
    {
        name: "German",
        language: "de",
        shortName: "ger",
        numNgrams: 4464999699,
    },
    {
        name: "Russian",
        language: "ru",
        shortName: "rus",
        numNgrams: 1526968135,
    },
];
const environ = "production"; // 'local' or 'staging' or 'production'
const pingUrl = "https://1.1.1.1"; // Cloudflare
let session = new Session();
let resultList = new ResultList(); // Dummy, see init()
let resultSizeLabel = new ResultSizeLabel(); // Dummy, see init()
// -------------------------------------------------------------------------------------------------
// Functions
// -------------------------------------------------------------------------------------------------
function clearSearchInput() {
    getSearchInput().val("");
    getClearButton().hide();
}
function findCorpus(shortName) {
    return corpora.find((corpus) => {
        return corpus.shortName === shortName;
    });
}
function formatNumber(value) {
    let result = "";
    const str = value.toString();
    for (let i = 0; i < str.length; ++i) {
        if (i !== 0 && (str.length - i) % 3 === 0)
            result = result + " ";
        result = result + str[i];
    }
    return result;
}
function formatNumberHuman(value) {
    if (value >= 1000000000)
        return (value / 1000000000).toFixed(2) + "&thinsp;B";
    if (value >= 1000000)
        return (value / 1000000).toFixed(2) + "&thinsp;M";
    if (value >= 1000)
        return (value / 1000).toFixed(2) + "&thinsp;K";
    return value;
}
function getBaseUrl() {
    switch (environ) {
        case "local":
            return "http://192.168.1.19:8080";
        case "staging":
            return "http://cubus.local:8080";
        case "production":
            return "https://api.ngrams.dev";
    }
    console.assert(false);
}
function getBody() {
    return $("body");
}
function getClearButton() {
    return $("#ng-clear-button");
}
function getCorpusButton() {
    return $("#ng-corpus-button");
}
function getCorpusModal() {
    return $("#ng-corpus-modal");
}
function getDash() {
    return $("#ng-dash");
}
function getDashMoreOperators() {
    return getDash().find(".main.second");
}
function getMenuButton() {
    return $("#ng-menu-button");
}
function getMenuFlyout() {
    return $("#ng-menu-flyout");
}
function getMessageWrapper() {
    return $("#ng-message-wrapper");
}
function getNgramStatsModal() {
    return $("#ng-ngram-stats-modal");
}
function getResultList() {
    return $("#ng-result-list");
}
function getResultMoreButton() {
    return $("#ng-result-more-button");
}
function getResultSizeLabel() {
    return $("#ng-result-size-label");
}
function getSearchBar() {
    return $("#ng-search-bar");
}
function getSearchInput() {
    return $("#ng-search-field input");
}
function getSearchMagnifier() {
    return $("#ng-search-magnifier");
}
function getSearchSpinner() {
    return $("#ng-search-spinner");
}
function getSettingsToggleCr() {
    return $("#ng-settings-cr");
}
function getSettingsToggleCs() {
    return $("#ng-settings-cs");
}
function getSettingsToggleEp() {
    return $("#ng-settings-ep");
}
function getSettingsToggleEs() {
    return $("#ng-settings-es");
}
function getSettingsToggleRi() {
    return $("#ng-settings-ri");
}
function getSettingsToggleRn() {
    return $("#ng-settings-rn");
}
function getSettingsToggleRt() {
    return $("#ng-settings-rt");
}
function getSettingsButton() {
    return $("#ng-settings-button");
}
function getSettingsModal() {
    return $("#ng-settings-modal");
}
function getTitle() {
    return $("#ng-title");
}
function handleSearchRequest(promise, isContinuationRequest = false) {
    const resultMoreButton = getResultMoreButton();
    promise
        .finally(() => {
        hideStatusMessage();
        if (!isContinuationRequest) {
            resultList.hide();
            resultList.clear();
            resultMoreButton.hide();
            resultSizeLabel.hide();
        }
    })
        .then((response) => {
        if (response.status == 200 || response.status == 400) {
            return response.json();
        }
    })
        .then((data) => {
        if (data.error) {
            switch (data.error.code) {
                case "INVALID_PARAMETER.START":
                    showMessageInvalidParameter();
                    break;
                case "INVALID_QUERY.BAD_ALTERNATION":
                    showMessageInvalidQueryBadAlternation();
                    break;
                case "INVALID_QUERY.BAD_COMPLETION":
                    showMessageInvalidQueryBadCompletion();
                    break;
                case "INVALID_QUERY.BAD_TEXT_GROUP":
                    showMessageInvalidQueryBadTextGroup();
                    break;
                case "INVALID_QUERY.TOO_EXPENSIVE":
                    showMessageInvalidQueryTooExpensive();
                    break;
                case "INVALID_QUERY.TOO_MANY_TOKENS":
                    showMessageInvalidQueryTooManyTokens(data.queryTokens);
                    break;
                case "INVALID_UTF8_ENCODING":
                    showMessageInvalidUtf8Encoding();
                    break;
            }
            return;
        }
        const ngrams = data.ngrams.map((ngram) => {
            return NgramLite.fromJson(ngram);
        });
        if (isContinuationRequest) {
            resultList.append(ngrams);
        }
        else if (ngrams.length > 0) {
            if (!isEqualQuery(session.query, data.queryTokens)) {
                showMessageQueryMapped(data.queryTokens);
            }
            resultList.set(ngrams);
            resultList.show();
        }
        else if (data.queryTokens.length > 3 && hasPosTagWildcard(data.queryTokens)) {
            showMessageEmptyResultPosTagWildcard();
        }
        else {
            showMessageEmptyResult();
        }
        if (data.nextPageToken) {
            resultMoreButton
                .off("click")
                .on("click", () => {
                showSpinner();
                const disabledClass = "disabled";
                resultMoreButton.addClass(disabledClass);
                const fetchPromise = fetch(makeSearchRequestUrl(session, data.nextPageToken));
                handleSearchRequest(fetchPromise, true);
                fetchPromise.finally(() => {
                    resultMoreButton.removeClass(disabledClass);
                    resultMoreButton.trigger("blur");
                    hideSpinner();
                });
            })
                .show();
        }
        else {
            resultMoreButton.hide();
        }
        if (ngrams.length > 0) {
            resultSizeLabel.setValue(resultList.size());
            resultSizeLabel.show();
        }
    })
        .catch((error) => {
        console.error(error);
        // Ping ok   -> NGRAMS server is down
        // Ping fail -> no internet connection
        fetch(pingUrl, { mode: "no-cors", cache: "no-cache" })
            .then((response) => {
            // response.ok might be false because of CORS.
            showMessageServerDown();
        })
            .catch(() => {
            showMessageOffline();
        });
        session.dirty = true;
    });
}
function hasPosTagWildcard(tokens) {
    const posTagWilcards = [
        "STAR_ADJ",
        "STAR_ADP",
        "STAR_ADV",
        "STAR_CONJ",
        "STAR_DET",
        "STAR_NOUN",
        "STAR_NUM",
        "STAR_PRON",
        "STAR_PRT",
        "STAR_VERB",
    ];
    return tokens.find((token) => posTagWilcards.includes(token.type)) != undefined;
}
function hideStatusMessage() {
    getMessageWrapper().hide();
}
function hideDash() {
    getSearchBar().removeClass("active");
}
function hideSpinner() {
    getSearchSpinner().hide();
    getSearchMagnifier().show();
}
function initCorpusModal() {
    const modal = getCorpusModal();
    modal.modal({
        transition: "fade",
        onShow: () => {
            // Nothing to do here.
        },
    });
    const list = modal.find(".list");
    for (let corpus of corpora) {
        const item = $(`
      <div class="item">
        <div class="content">
          <div class="header">${corpus.name}</div>
          ${formatNumberHuman(corpus.numNgrams)} ngrams
        </div>
        <div class="icon">
          <i class="fa-solid fa-circle-check"></i>
        </div>
      </div>
    `)
            .on("click", () => {
            setCorpusUiElements(corpus);
            session.corpus = corpus;
            session.dirty = true;
            triggerSearchRequest(true);
            modal.modal("hide");
        })
            .data("shortName", corpus.shortName);
        list.append(item);
    }
}
function initDash() {
    const dash = getDash();
    const input = getSearchInput();
    dash.find(".operator").on("click", function () {
        const query = input.val();
        const operator = $(this).data("text");
        if (operator == "~") {
            input.val(`${query}${operator} `);
        }
        else {
            input.val(query.endsWith(" ") ? `${query}${operator} ` : `${query} ${operator} `);
        }
    });
    dash.find(".more").on("click", function () {
        $(this).text(getDashMoreOperators().toggle().is(":visible") ? "Show less" : "Show more");
    });
}
function initMenuFlyout() {
    getMenuFlyout().flyout({});
}
function initNgramStatsModal() {
    const modal = getNgramStatsModal();
    const chart = modal.find(".chart");
    modal.modal({
        closable: false,
        onShow: () => {
            const ngramLite = modal.data("ngramLite");
            modal.find("[data-name=ngram-text]").text(ngramLite.toString());
            modal.find("[data-name=abs-tmc]").text(ngramLite.absTotalMatchCount);
            const ngramFull = modal.data("ngramFull");
            modal.find("[data-name=first-year]").text(ngramFull.stats[0].year);
            modal.find("[data-name=last-year]").text(ngramFull.stats[ngramFull.stats.length - 1].year);
            chart.empty();
        },
        onVisible: () => {
            if (document.defaultView) {
                const ngramFull = modal.data("ngramFull");
                const height = document.defaultView.innerHeight - 300 + "px";
                const histogram = new NgramStatsHistogram(ngramFull.stats, height);
                chart.append(histogram.container);
                // Init click handlers
                const activeClass = "active";
                const absButton = modal.find(".absolute").on("click", () => {
                    if (absButton.hasClass(activeClass))
                        return;
                    relButton.removeClass(activeClass);
                    absButton.addClass(activeClass);
                    histogram.showAbsoluteNumbers();
                });
                const relButton = modal.find(".relative").on("click", () => {
                    if (relButton.hasClass(activeClass))
                        return;
                    absButton.removeClass(activeClass);
                    relButton.addClass(activeClass);
                    histogram.showRelativeNumbers();
                });
            }
        },
    });
}
function initSearchBar() {
    const searchInput = getSearchInput();
    const clearButton = getClearButton();
    const searchButton = getSearchMagnifier();
    const active = "active";
    const search = () => {
        triggerSearchRequest(true);
    };
    searchButton.on("click", search);
    searchInput
        .on("click", () => {
        showDash();
    })
        .on("input", (event) => {
        clearButton.toggle(event.target.value.length > 0);
        searchButton.toggleClass(active, event.target.value.length > 0);
        session.query = event.target.value;
        session.dirty = true;
    })
        .on("keydown", (event) => {
        if (event.key === "Enter") {
            search();
        }
    });
    clearButton.on("click", () => {
        clearSearchInput();
        searchButton.removeClass(active);
        searchInput.trigger("focus");
    });
    getSettingsButton().on("click", () => {
        getSettingsModal().modal("show");
    });
    getCorpusButton().on("click", () => {
        getCorpusModal().modal("show");
    });
    initDash();
}
function initSettingsModal() {
    const modal = getSettingsModal();
    const csToggle = getSettingsToggleCs();
    const crToggle = getSettingsToggleCr();
    const epToggle = getSettingsToggleEp();
    const esToggle = getSettingsToggleEs();
    const riToggle = getSettingsToggleRi();
    const rnToggle = getSettingsToggleRn();
    const rtToggle = getSettingsToggleRt();
    let oldFlags = null;
    modal.modal({
        transition: "fade",
        onShow: () => {
            oldFlags = structuredClone(session.flags);
            csToggle.prop("checked", session.flags.caseSensitive);
            crToggle.prop("checked", session.flags.collapseResult);
            epToggle.prop("checked", session.flags.excludePunctuationMarks);
            esToggle.prop("checked", session.flags.excludeSentenceBoundaries);
            riToggle.prop("checked", session.flags.dontInterpretOperators);
            rnToggle.prop("checked", session.flags.dontNormalizeQueryTerms);
            rtToggle.prop("checked", session.flags.dontTokenizeQueryTerms);
        },
    });
    modal.find(".accent.button").on("click", () => {
        modal.modal("hide");
        session.flags.caseSensitive = csToggle.prop("checked");
        session.flags.collapseResult = crToggle.prop("checked");
        session.flags.excludePunctuationMarks = epToggle.prop("checked");
        session.flags.excludeSentenceBoundaries = esToggle.prop("checked");
        session.flags.dontInterpretOperators = riToggle.prop("checked");
        session.flags.dontNormalizeQueryTerms = rnToggle.prop("checked");
        session.flags.dontTokenizeQueryTerms = rtToggle.prop("checked");
        getSettingsButton().toggleClass("active", session.flags.toBoolean());
        session.dirty = !session.flags.equals(oldFlags);
        triggerSearchRequest(true);
    });
    csToggle.switchify();
    crToggle.switchify();
    epToggle.switchify();
    esToggle.switchify();
    riToggle.switchify();
    rnToggle.switchify();
    rtToggle.switchify();
}
function isEqualQuery(userQuery, queryTokens) {
    const lhs = userQuery
        .trim()
        .split(/\s+/)
        .map((token) => {
        if (token.startsWith("\\")) {
            // See `recognizeEscapeSequence` C++ function.
            switch (token) {
                case "\\/":
                    return "/";
                case "\\~":
                    return "~";
                case "\\*":
                    return "*";
                case "\\**":
                    return "**";
                case "\\*_ADJ":
                    return "*_ADJ";
                case "\\*_ADP":
                    return "*_ADP";
                case "\\*_ADV":
                    return "*_ADV";
                case "\\*_CONJ":
                    return "*_CONJ";
                case "\\*_DET":
                    return "*_DET";
                case "\\*_NOUN":
                    return "*_NOUN";
                case "\\*_NUM":
                    return "*_NUM";
                case "\\*_PRON":
                    return "*_PRON";
                case "\\*_PRT":
                    return "*_PRT";
                case "\\*_VERB":
                    return "*_VERB";
                case "\\_START_":
                    return "_START_";
                case "\\_END_":
                    return "_END_";
                default:
                    if (token.startsWith('\\"')) {
                        return token.slice(1);
                    }
            }
        }
        else if (token.endsWith('\\"')) {
            return token.slice(0, -2) + '"';
        }
        else if (token.endsWith("\\~")) {
            return token.slice(0, -2) + "~";
        }
        return token;
    })
        .join(" ");
    const rhs = queryTokens === null || queryTokens === void 0 ? void 0 : queryTokens.map((token) => token.text).join(" ");
    return lhs == rhs;
}
function isOpenLayout() {
    return getBody().hasClass("open");
}
function makeHistoryUrl(session) {
    const params = new URLSearchParams();
    params.append("corpus", session.corpus.shortName);
    params.append("query", session.query.trim());
    const flags = session.flags.toString();
    if (flags.length > 0) {
        params.append("flags", flags);
    }
    return `/?${params}`;
}
function makeNgramRequestUrl(corpusShortName, ngramId) {
    return `${getBaseUrl()}/${corpusShortName}/${ngramId}`;
}
function makeSearchRequestUrl(session, start) {
    const params = new URLSearchParams();
    params.append("query", session.query.trim());
    const flags = session.flags.toString();
    if (flags.length > 0) {
        params.append("flags", flags);
    }
    if (start && start.length > 0) {
        params.append("start", start);
    }
    return `${getBaseUrl()}/${session.corpus.shortName}/search?${params}`;
}
function makeSessionFromUrl(params) {
    // Required
    const corpusShortName = params.get("corpus");
    if (!corpusShortName) {
        return null;
    }
    const corpus = findCorpus(corpusShortName);
    if (!corpus) {
        return null;
    }
    // Required
    const query = params.get("query");
    if (!query) {
        return null;
    }
    const session = {
        corpus: corpus,
        query: query,
        flags: new SearchFlags(),
        dirty: true,
    };
    // Optional
    const flags = params.get("flags");
    if (flags) {
        session.flags = SearchFlags.fromString(flags);
    }
    return session;
}
function makeSnippetWarningEmptyResult() {
    return $(`
    <div class="warning">
      <div class="icon">🤔</div>
      <div class="text">We couldn't find any text snippets for this ngram.</div>
    </div>
  `);
}
function makeSnippetWarningUnreachable() {
    return $(`
    <div class="warning">
      <div class="icon">😴</div>
      <div class="text">Google Books is not responding. Try again later.</div>
    </div>
  `);
}
function makeSnippetsList(snippets) {
    const list = $("<div class='ui list'></div>");
    for (const snippet of snippets) {
        list.append($(`<div class="item">${snippet}</div>`));
    }
    return list;
}
function makeSnippetsRequestUrl(ngramText, language) {
    // https://developers.google.com/books/docs/overview
    const params = new URLSearchParams({
        q: '"' + ngramText + '"',
        fields: "items(searchInfo/textSnippet)",
        langRestrict: language,
        maxResults: String(40), // "Maximum allowable value: maxResults=40"
    });
    return "https://www.googleapis.com/books/v1/volumes?" + params;
}
function showDash() {
    getSearchBar().addClass("active");
}
function showMessageEmptyResult() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">We couldn't find any matching ngrams.</div>
        <div class="subtext">Please check for spelling mistakes or relax your query.</div>
       </div>`)
        .show();
}
function showMessageEmptyResultPosTagWildcard() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">We couldn't find any matching ngrams.</div>
        <div class="subtext">Note that part-of-speech wildcards do not work for four- and fivegrams.</div>
       </div>`)
        .show();
}
function showMessageInvalidParameter() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">Something was wrong with your request.</div>
       </div>`)
        .show();
}
function showMessageInvalidQueryBadAlternation() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">
          Your query uses the <b>alternation</b> operator incorrectly.<br />
          To search for a literal &nbsp;<b>/</b>&nbsp; use &nbsp;<b>\\/</b>&nbsp; instead.
        </div>
        <div class="subtext">Please check our <a href="#">query language guide</a> for details.</div>
       </div>`)
        .show();
}
function showMessageInvalidQueryBadCompletion() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">
          Your query uses the <b>completion</b> operator incorrectly.<br />
          To search for a literal &nbsp;<b>~</b>&nbsp; use &nbsp;<b>\\~</b>&nbsp; instead.
        </div>
        <div class="subtext">Please check our <a href="#">query language guide</a> for details.</div>
       </div>`)
        .show();
}
function showMessageInvalidQueryBadTextGroup() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">
          Your query uses the <b>text group</b> operator incorrectly.<br />
          To search for a literal &nbsp;<b>"</b>&nbsp; use &nbsp;<b>\\"</b>&nbsp; instead.
        </div>
        <div class="subtext">Please check our <a href="#">query language guide</a> for details.</div>
       </div>`)
        .show();
}
function showMessageInvalidQueryTooExpensive() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">Your query is too expensive to process.</div>
        <div class="subtext">Please check our <a href="#">query language guide</a> for details.</div>
       </div>`)
        .show();
}
function showMessageInvalidQueryTooManyTokens(tokens) {
    const query = tokens.map((token) => token.text).join(" ");
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text"><b>${query}</b><br />is too long to match any fivegram.</div>
        <div class="subtext">Make it shorter and try again.</div>
       </div>`)
        .show();
}
function showMessageInvalidUtf8Encoding() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤔</div>
        <div class="text">Your query is not properly UTF-8 encoded.</div>
       </div>`)
        .show();
}
function showMessageOffline() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">🤨</div>
        <div class="text">It seems like you are offline.</div>
        <div class="subtext">Please check your internet connection.</div>
       </div>`)
        .show();
}
function showMessageQueryMapped(tokens) {
    const query = tokens.map((token) => token.text).join(" ");
    getMessageWrapper()
        .html(`<div class="ng message">
        <div class="icon">🧐</div>
        <div class="text">We mapped your query to &nbsp;<b>${query}</b>&nbsp; to better match the underlying ngrams.</div>
        <div class="subtext">You can disable tokenization of query terms in search settings.</div>
       </div>`)
        .show();
}
function showMessageServerDown() {
    getMessageWrapper()
        .html(`<div class="ng centered message">
        <div class="icon">😴</div>
        <div class="text">It seems like the backend is down.</div>
        <div class="subtext">Please try again later.</div>
       </div>`)
        .show();
}
function showSpinner() {
    getSearchMagnifier().hide();
    getSearchSpinner().show();
}
function setCorpusUiElements(corpus) {
    const placeholder = `Search ${formatNumber(corpus.numNgrams)} ngrams`;
    getSearchInput().attr("placeholder", placeholder);
    getCorpusButton().text(corpus.name.split(" ")[0]); // Split off "(simplified)"
    const items = getCorpusModal().find(".item");
    items.find(".icon").hide();
    items
        .filter((index, item) => {
        return $(item).data("shortName") === corpus.shortName;
    })
        .find(".icon")
        .show();
}
function switchToOpenLayout() {
    if (isOpenLayout())
        return null;
    const body = getBody()[0];
    const options = {
        duration: 500,
        easing: "ease",
        fill: "forwards",
    };
    return body.animate([{ opacity: 0 }], options).finished.then(() => {
        body.classList.add("open");
        return body.animate([{ opacity: 1 }], options);
    });
}
function triggerSearchRequest(pushHistoryEntry) {
    if (session.dirty) {
        const query = session.query.trim();
        if (query) {
            session.dirty = false;
            hideDash();
            showSpinner();
            if (pushHistoryEntry) {
                history.pushState(session, "", makeHistoryUrl(session));
            }
            const fetchPromise = fetch(makeSearchRequestUrl(session));
            const animatePromise = switchToOpenLayout();
            if (animatePromise) {
                animatePromise.then(() => {
                    hideSpinner();
                    handleSearchRequest(fetchPromise);
                });
            }
            else {
                hideSpinner();
                handleSearchRequest(fetchPromise);
            }
        }
    }
}
// -------------------------------------------------------------------------------------------------
// Public
// -------------------------------------------------------------------------------------------------
export function init() {
    session.corpus = corpora[0];
    initMenuFlyout();
    initCorpusModal();
    initNgramStatsModal();
    initSettingsModal();
    initSearchBar();
    setCorpusUiElements(session.corpus);
    resultList = new ResultList("#ng-result-list");
    resultSizeLabel = new ResultSizeLabel("#ng-result-size-label");
    getMenuButton().on("click", () => {
        getMenuFlyout().flyout("show");
    });
    $("#ng-nellie").popup({
        inline: true,
        hoverable: true,
        forcePosition: true,
        position: "top right",
    });
    const body = getBody();
    // Load initial URL
    const initialSession = makeSessionFromUrl(new URLSearchParams(document.location.search));
    if (initialSession) {
        session = initialSession;
        console.log(session);
        getSearchInput().val(session.query);
        setCorpusUiElements(session.corpus);
        triggerSearchRequest(false);
    }
    else {
        body.css("opacity", 1);
    }
    body.on("click", (event) => {
        if ($(event.target).closest(getSearchBar()).length == 0) {
            // Hide the dash if clicked anywhere outside.
            hideDash();
        }
    });
    // Back button support
    window.addEventListener("popstate", (event) => {
        console.log("Pop state", document.location.search, event.state);
        if (event.state) {
            session = event.state;
            session.dirty = true;
            getSearchInput().val(session.query);
            triggerSearchRequest(false);
        }
        else {
            window.location.href = "/";
            clearSearchInput();
        }
    });
}
$(function () {
    init();
});
